'use strict';

// es6 sample code
// var [a, , b] = [1,2,3];

// 'sedbadmin' is the ONLY JavaScript global variable we use in this project.
var sedbadmin = {
  PICKED_ZOOM_LEVEL: 18, // google map zoom level we use for selected location.
  NON_PICKED_ZOOM_LEVEL: 9, // zoom 9 cover all Hong Kong area. (at 300px height). For not selected location.
  map: null, // google map object.
  map_marker: null, // google map marker. simple design, always have one and only one marker on the map.
  geocoder: null, // google geocoder, for address to latlng.
  hk_bounds: null, // google LatLngBounds.
  hk_center: {lat: 22.396428, lng: 114.10949700000003},
  initMap: function(){ // trigger by Google API callback.
    var readyonly = $('#map_container').hasClass('readyonly');
    var lat = Number($('input#lat').val()),
        lng = Number($('input#lng').val()),
        zoom = sedbadmin.NON_PICKED_ZOOM_LEVEL,
        latlng = sedbadmin.hk_center;
    if (isNaN(lat) || isNaN(lng) || (lat === 0 && lng === 0)) {
      // default Hong Kong (center).
    } else {
      zoom = sedbadmin.PICKED_ZOOM_LEVEL;
      latlng = {lat: lat, lng: lng};
    }
    var mapOptions = {
      zoom: zoom,
      center: latlng,
    };
    // create a hk area border.
    sedbadmin.hk_bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(22.0977286527726, 113.73115532519535), // sw
      new google.maps.LatLng(22.60577083081293, 114.47135918261722) // ne
    );
    // create map
    sedbadmin.map = new google.maps.Map(document.getElementById("map_container"), mapOptions);
    // add marker to map
    sedbadmin.map_marker = new google.maps.Marker({
      position: latlng,
      map: sedbadmin.map,
      draggable: !readyonly,
    });
    // add marker drop event callback.
    sedbadmin.map_marker.addListener('dragend', function() {
      // update hidden input field
      $('input#lat').val(this.getPosition().lat());
      $('input#lng').val(this.getPosition().lng());
      // set form dirty
      sedbadmin.setFormDirty();
    });
    // create geocoder for address to latlng.
    sedbadmin.geocoder = new google.maps.Geocoder();
  },
  address2latlng: function(address){// get latlng from address, and update map marker position.
    sedbadmin.geocoder.geocode({
      address: address,
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingRequests
      bounds: sedbadmin.hk_bounds,
      region: 'HK',
      // Leonardo: if we send the request with 'componentRestrictions: HK', it even worse...
      // For example:
      //    新界大埔富亨邨鄰里社區中心4樓, it cannot found, but return one result which is center of Hong Kong...
      // So don't use it.
      // componentRestrictions: {
      //   country: 'HK'
      // }
    }, function(results, status){
      if (status === google.maps.GeocoderStatus.OK) {
        var filtered_hk_reuslts = [];
        results.forEach(function(r){
          if (sedbadmin.hk_bounds.contains(r.geometry.location)){
            filtered_hk_reuslts.push(r);
          }
        });
        if (filtered_hk_reuslts.length > 0) {
          var latlng = filtered_hk_reuslts[0].geometry.location; // always use first result.
          sedbadmin.map_marker.setPosition(latlng); // update marker position.
          sedbadmin.map.setZoom(sedbadmin.PICKED_ZOOM_LEVEL); // zoom in map
          sedbadmin.map.panTo(latlng); // pan map to that location.
          // update hidden field.
          $('input#lat').val(latlng.lat());
          $('input#lng').val(latlng.lng());
          // set form dirty
          sedbadmin.setFormDirty();
        } else {
          alert('Location not found. Please try to update the address, or drag-and-drop the marker on the map.');
        }
      } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        alert('Location not found. Please try to update the address, or drag-and-drop the marker on the map.');
      } else {
        alert('Google Map Geocoder Error: '+status);
      }
    });
  },
  setFormDirty: function(){
    $('form.monitor').addClass('dirty');
  }
};

if ( typeof CKEDITOR != "undefined" && CKEDITOR.env.ie && CKEDITOR.env.version < 9 ) {
  CKEDITOR.tools.enableHtml5Elements( document );
}

if (window && !window.sedbadmin) {
  // export to global, if needed
  window.sedbadmin = sedbadmin;
}


$(function() { // js ready
  // change to 'loading' text when submit button being clicked
  $('form.monitor').on('submit', function () {
    $("button[type=submit]", this).button('loading');
  })

  // check if the form is modified
  $("form.monitor :input").on('change keydown', function(){
    $(this).parents('form').addClass('dirty');
  });

  // check if the form is going to submit
  $("form.monitor").on('submit', function(){
    $(this).addClass('submit');
  });

  // if form is modified, show warning message when leaving this page.
  $(window).on('beforeunload', function() {
    if ($('form.monitor.dirty').not('.submit').length > 0) {
      return "You haven't save yet!"
    }
  });

  // button for address to latlng
  // follow bootstrap JS design, just use HTML attributes to trigger JS. No need write additional code for each one.
  $("button[data-toggle=geocoder]").on('click', function(){
    var target = $(this).data('target');
    target = target.replace('[', '\\[');
    target = target.replace(']', '\\]');
    var address = $('#'+target).val();
    sedbadmin.address2latlng(address);
  });

  // toggle hide/show address related input
  $("input[name=has_address]:radio").on('change', function(){
    sedbadmin.setFormDirty();
    if ($(this).val() == "1") {
      $('#map_and_address').show();
      // init map and reset everything (google map won't init when it was hidden.)
      google.maps.event.trigger(sedbadmin.map, 'resize');
      sedbadmin.map.panTo(sedbadmin.hk_center);
      sedbadmin.map_marker.setPosition(sedbadmin.hk_center);
      sedbadmin.map.setZoom(sedbadmin.NON_PICKED_ZOOM_LEVEL);
    } else {
      // clear all input value
      $('#map_and_address input').val('');
      $('#map_and_address').hide();
    }
  });

  // disable download (export xlsx) <a> link after click
  $('.btn-download').on('click', function(){
    $(this).text(' Downloading');
    $(this).prepend($('<span class="glyphicon glyphicon-hourglass"/>'));
    $(this).addClass('disabled');
  });

  // auto upload attachment after file selected
  $('.fileinput-button input').on('change', function(){
    var $form = $(this).parents('form');
    $('.fileinput-button', $form).addClass('disabled');
    $('.fileinput-button>span', $form).text('Uploading');
    $form.submit();
  });

  // delete confirm
  $('.delete-form').on('submit', function(){
    if (!confirm('Are you sure you want to delete this item?')) {
      return false;
    }
  });

  // rich editor
  $('.rich-editor').each(function(){
    CKEDITOR.replace($(this).attr('id'));
  });

  $('.not-yet-implemented').on('click', function(){
    alert('not yet implemented.');
    return false;
  });

  // select2
  $('#social_enterprise\\[tag_ids\\]\\[\\]').select2();
  $('.single-select2').select2();

  // datetimepicker
  $('.datetimepicker').each(function(){
    var id = $(this).attr('id');
    // console.log($('#'+id).length)
    $('#'+id).datetimepicker({format: 'YYYY-MM-DD HH:mm'});
  });

  // others
  $('.other_checkbox_with_input').each(function(){
    var wrapper = $(this);
    var checkbox = $(':checkbox', wrapper);
    var checkbox_handler = function(checkbox){
      if (checkbox.is(':checked')) {
        $('input[type=text]', wrapper).prop('disabled', false).show();
      } else {
        $('input[type=text]', wrapper).prop('disabled', true).hide();
      }
    }
    // monitor
    checkbox.on('click', function(){
      checkbox_handler($(this));
    });
    // init
    checkbox_handler(checkbox);
  });

});
